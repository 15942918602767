<template>
  <div>
    <v-dialog
      v-model="localShow"
      max-width="390"
      persistent
    >
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("report.messages.scheduled_question") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined rounded @click="closeModal(false)">
            {{ $t("report.action.no") }}
          </v-btn>
          <v-btn color="secondary" rounded @click="closeModal(true)">
            {{ $t("report.action.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localShow: this.show, // Manejo interno del estado del modal
    };
  },
  watch: {
    show(val) {
      this.localShow = val; // Sincroniza cambios desde el padre
    },
    localShow(val) {
      this.$emit("update:show", val); // Emite cambios al padre
    },
  },
  methods: {
    closeModal(confirmed) {
      this.localShow = false; // Cierra el modal
      this.$emit("confirmed", confirmed); // Emite el evento al padre con la respuesta
    },
  },
};
</script>

  