import { orderBy } from "lodash";

export type NestedObject = { [key: string]: boolean | NestedObject };

export function filterTrueProperties(obj: NestedObject): NestedObject {
  const filteredObj: NestedObject = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === "boolean") {
        if (value === true) {
          filteredObj[key] = value;
        }
      } else if (typeof value === "object") {
        const filteredSubObj = filterTrueProperties(value);
        if (Object.keys(filteredSubObj).length > 0) {
          filteredObj[key] = filteredSubObj;
        }
      }
    }
  }

  return filteredObj;
}

// Utility function to safely get a property or return an empty array if not found
export const getProperty = (obj: any, prop: string, defaultValue: any = []) =>
  obj.hasOwnProperty(prop) ? obj[prop] : defaultValue;

// Function to sort and assign to store resources
export const assignSortedResource = (key: string, value: any) => {
  if (Array.isArray(value) && value.length > 0) {
    if (key === "ooh_distance") return orderBy(value, (a: any) => Number(a.key), ["asc"]);
    return orderBy(value, (a: any) => a.value, ["asc"]);
  }
  return [];
};

/**
 * Verifica si alguno de los valores del objeto 'metrics' es 1
 * @param metrics
 * @returns
 */
export function hasSelectedMetric(metrics: { [key: string]: any }) {
  // Recorremos todas las propiedades del objeto 'metrics'
  return Object.values(metrics).includes(1); // Verificamos si alguno de los valores es 1
}
