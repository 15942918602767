import Vue from "vue";
import i18n from "@/plugins/i18n";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import { DataDisplayItem, ReportDataEntity } from "@/interfaces/report";
import { mapGetters } from "vuex";
import { GetConditionalMetrics } from "@/views/Admin/Reports/V2/utils/reportsUtils";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";

export default Vue.extend({
	name: "ListData",

	props: {
		values: {
			type: Object,
			default: function () {
				return {};
			},
		},
		type: {
			type: String,
			required: true,
		},
		loading: {
			type: Boolean,
			default: true,
		},
	},

	components: {
		CardSwitch,
	},

	data: () => ({
		dataDisplay: new Array<DataDisplayItem>(),
		conditionalMetrics: GetConditionalMetrics(),
		selectAll:false,
		selectAllAction:false,
	}),

	created() {
		this.$nextTick(async () => {
			await this.preparedItemsDisplay();
		});
	},

	mounted() {
		this.$nextTick(async () => {
			await this.preparedItemsDisplay();
		});
	},

	computed: {
		...mapGetters("report_v2", ["storeReport", "storeSchedule"]),

		getStoreSchedule(): ScheduleEntity {
			return this.storeSchedule;
		},

		getStoreReport(): ReportDataEntity {
			return this.storeReport;
		},

		getItems() {
			return this.values;
		},
		getDisplayItems(): DataDisplayItem[] {
			return this.dataDisplay;
		},
		getType() {
			return this.type;
		},

		getSizeDimensions() {
			return this.getStoreReport.size;
		},

		isMetricsMaidsReport() {
			return this.getType === 'metrics' && this.getStoreReport.report_type === 'maids_report';
		},

		isStoreAttributionReport() {
			return this.getStoreReport.report_type === 'store_atributtion_report';
		},

		isMetricsInsigthsReport() {
			return this.getType === 'metrics' && this.getStoreReport.report_type === 'insigths_report';
		},
		isStoreGeoCoordinatesReport() {
			return this.getType === 'metrics' && this.getStoreReport.report_type === 'geo_coordinates_report';
		},
		isOohReport() {
			return this.getType === 'metrics' && this.getStoreReport.report_type === 'ooh_report';
		},

		isFiveColumn() {
			const hasParentItems = this.getDisplayItems.filter((r) => r.isParent).length >= 1;
			return hasParentItems && !this.isMetricsMaidsReport && !this.isStoreAttributionReport && !this.isStoreGeoCoordinatesReport;
		},
		
		/**
		 * Determinar el numero de columna segun el tipo de reporte
		 * @returns 
		 */
		getCheckboxActionClass() {
			return {
				'five-column': this.isFiveColumn,
				'four-column': this.isStoreAttributionReport,
				'two-column': this.isStoreGeoCoordinatesReport,
				'one-column': this.isMetricsMaidsReport,
			}
		}
	},

	methods: {
		async preparedItemsDisplay() {
			this.dataDisplay = [];
			
			for (const [key, value] of Object.entries(this.getItems)) {

				const itemValue: any = value;

				let parent = {
					key: key,
					title: i18n.t(`report.v2.${this.type}.${key}`),
					items: [],
					isParent: true,
					option: false,
				} as DataDisplayItem;

				this.dataDisplay.push(parent);

				for (const [subKey, subValue] of Object.entries(itemValue)) {
					const _itemValue: any = subValue;
					let subItem = {
						key: subKey,
						title: i18n.t(`report.v2.${this.type}.${subKey}`),
						value: this.getItems[key][subKey],
						isParent: false,
						option: this.validateOption(subKey, _itemValue),
					} as DataDisplayItem;
					this.dataDisplay.push(subItem);
				}
			}
		},
		validateOption(subKey:string, itemValue:number){
			if(this.selectAllAction){
				return this.selectAll;
			}
			return  (subKey === 'less_50_mts' && itemValue > 0)
		},
		async syncDataDisplay(){
			const dataDisplayList :  DataDisplayItem[] = this.dataDisplay;
			dataDisplayList.forEach(element => {
				this.setItemSelected(element)
				//this.$emit("set-selected-item", { type: this.type, value: element });
			});
		},

		async setItemSelected(item: any) {
			if (this.conditionalMetrics.hasOwnProperty(item.key)) {
				const selected = this.conditionalMetrics[item.key].selected;
				selected.forEach((c) => {
					(this.getDisplayItems as DataDisplayItem[]).forEach((d) => {
						if (d.key == c) {
							d.option = item.option;
						}
					});
				});
			}
			this.$emit("set-selected-item", { type: this.type, value: item });
		},

		/**
		 * Cargar dimensiones|metricas del schedule obtenido
		 * @param type
		 */
		async syncData(type: "dimensions" | "metrics") {
			const displayItems: DataDisplayItem[] = this.getDisplayItems;
			const element = this.getStoreReport[type];

			for (const [key, value] of Object.entries(element)) {
				const displayItem: DataDisplayItem | undefined =
					displayItems.find((d) => d.key === key);

				if (displayItem) {
					displayItem.option = Boolean(value);
				}
			}
		},
	},

	watch: {
		values: {
			async handler(val, oldVal) {
				await this.preparedItemsDisplay();
				await this.syncData("dimensions");
				await this.syncData("metrics");
				this.selectAll = false;
			},
			deep: true,
			immediate: false,
		},
		"getStoreReport.dimensions": {
			async handler(val, oldVal) {
				await this.syncData("dimensions");
			},
			deep: true,
			immediate: false,
		},

		"getStoreReport.metrics": {
			async handler(val, oldVal) {
				await this.syncData("metrics");
			},
			deep: true,
			immediate: false,
		},

		"getStoreReport.report_year": {
			async handler(val, oldVal) {
				await this.preparedItemsDisplay();
				await this.syncData("dimensions");
				await this.syncData("metrics");
			},
			deep: true,
			immediate: false,
		},

		"getStoreReport.date_range": {
			async handler(val, oldVal) {
				await this.preparedItemsDisplay();
				await this.syncData("dimensions");
				await this.syncData("metrics");
			},
			deep: true,
			immediate: false,
		},

		getDisplayItems: {
			async handler(val: DataDisplayItem[]) {
				const selected = val.filter((v) => v.option === true);
				const selectedNumber: Record<string, number> = {};

				selected.forEach((s) => {
					selectedNumber[s.key] = 1;
				});

				const keys: string[] =
					this.getStoreSchedule.form.prepareKeys(selectedNumber);

				this.getStoreSchedule.form[this.type] = keys;
			},
			deep: true,
			immediate: true,
		},
		async selectAll() {
			this.selectAllAction = true;
			await this.preparedItemsDisplay();
			await this.syncDataDisplay();
			await this.syncData("dimensions");
			await this.syncData("metrics");
			this.selectAllAction = false;
		}
	},
});
