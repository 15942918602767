import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { SchedulerExpansionType, getExpansionPanel } from "./utils";
import { includes, isEmpty } from "lodash";
import { ExpansionPanelEntity } from "@/models/persons/v10/Implements";
import { ReportDataEntity, ResourceDataEntity } from "@/interfaces/report";
import { ActivePanelTab } from "@/interfaces/persons/v10/types";
import { ReportResourceDataEntity } from "@/models/Reports/v2/Report";
import { scrollToTop } from "@/utils/services-global";
import Alertize from "@/components/Alertize.vue";
import Separator from "@/components/Content/Separator.vue";
import CardCollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CardCollapsePanel/index.vue";
import SchedulerList from "@/views/Admin/Reports/V2/Dependencies/Schedulers/List/index.vue";
import SchedulerForm from "@/views/Admin/Reports/V2/Dependencies/Schedulers/Form/index.vue";
import ReportType from "@/views/Admin/Reports/V2/Form/Sections/ReportType/index.vue";
import ReportFilters from "@/views/Admin/Reports/V2/Form/Sections/ReportFilters/index.vue";
import { getDimensionMetricParsed } from "@/models/Reports/v2/utils";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";
import { PayloadResource } from "@/interfaces/reports/v2/payload";
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "ReportScheduler",
	props: {},
	components: {
		Alertize,
		Separator,
		CardCollapsePanel,
		SchedulerList,
		SchedulerForm,
		ReportType,
		ReportFilters,
	},
	data: () => ({
		activePanel: [
			ActivePanelTab.SCHEDULER_REPORT,
			ActivePanelTab.SAVE_SCHEDULE,
		],
		itemsExpansion: [] as ExpansionPanelEntity[],
		panels: [] as ExpansionPanelEntity[],
		dataReportEdit: [],
		observer: null, // Para almacenar el MutationObserver
	}),
	created() {
		this.$nextTick(async () => {
			try {
				this.itemsExpansion = await getExpansionPanel();
				this.activePanel = [ActivePanelTab.SCHEDULER_REPORT];
			} catch (error) {
				console.error("ReportScheduler:error", error);
			}
		});
	},
	async mounted() {
		this.$nextTick(async () => {});
		this.initMutationObserver();
	},
	beforeDestroy() {
        this.disconnectObserver();
    },
	computed: {
		...mapGetters("account", ["isAccountMaster"]),
		...mapGetters("generate_report", ["attempResponse"]),
		...mapGetters("report_v2", [
			"storeSchedule",
			"storeReport",
			"storeResources",
			"storeResourceData",
		]),
		getStoreSchedule(): ScheduleEntity {
			return this.storeSchedule;
		},
		getStoreReport(): ReportDataEntity {
			return this.storeReport;
		},
		getStoreResources(): ResourceDataEntity {
			return this.storeResources;
		},
		getStoreResourceData(): ReportResourceDataEntity {
			return this.storeResourceData;
		},
		getExpansionType() {
			return SchedulerExpansionType;
		},
		getPanelsItems() {
			let panels: ExpansionPanelEntity[] = this.itemsExpansion;

			const isEdit: Boolean = this.getStoreSchedule.form.hasID();

			/**
			 * Modo edicion {isEdit}
			 * En el modo edicion no mostrar el listado de scheduler
			 */
			if (isEdit) {
				panels = panels.filter(
					(p) =>
						p.type !==
						(SchedulerExpansionType.SCHEDULER_REPORT as any)
				);

				// Comprobar si el panel es igual de SAVE SCHEDULE y mostrarlo
				panels.forEach((p) => {
					if (p.id === ActivePanelTab.SAVE_SCHEDULE) {
						p.show = true; 
					}
				});

			}
			else {
				panels.forEach((p) => {
					if (p.id === ActivePanelTab.SAVE_SCHEDULE) {
						p.show = false;
					}
					if (p.id === ActivePanelTab.SCHEDULER_REPORT) {
						p.expandable = true;
						p.show = true;
					}
				});
			}
			this.addActivePanels();
			this.panels = panels;

			return panels;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("report_v2", [
			"scheduleShow",
			"setScheduleEdit",
			"matchDataScheduleShow",
			"setSPResources",
		]),

		addActivePanels() {
			this.activePanel = [
				ActivePanelTab.SCHEDULER_REPORT,
			];
		},

		isExpanded(itemId: number) {
			return this.activePanel.includes(itemId);
		},
		isSection(itemType: string, type: string) {
			return itemType === type;
		},
		async updateReport(params: any) {
			try {
				this.setLoadingData(TypeLoading.loading);

				/**
				 * Fetch de show:scheduler
				 */
				let scheduleShow = await this.scheduleShow(params.id);

				scrollToTop();

				this.setLoadingData();

				/**
				 * Match de datos del result de edit scheduler
				 */
				scheduleShow = await this.matchDataScheduleShow({
					params,
					scheduleShow,
				});
				
				this.attempResponse.setSuccess(true);

				/**
				 * Extraer y preparar el payload para {fetchResourceSP}
				 * Fetch de {fetchResourceSP} y set de resources en el store
				 */
				await this.setSPResources({
					params,
					scheduleShow,
				});

				/**
				 * Preparar los filtros filtrados por los datos de scheduler show
				 */
				const reportFiltered: PayloadResource = new PayloadResource(
					params.filters,
					this.storeResources
				);
				const appliedFilters = await reportFiltered.applyFilters();

				/**
				 * Aplicar filtros en el store de report
				 */
				await this.storeReport.setFilters(appliedFilters);
				
				/**
				 * Obtener los keys de los filtros
				 */
				const keys = await this.storeSchedule.form.prepareFilters(
					appliedFilters
				);
				
				/**
				 * Aplicar filtros [key] a form scheduler
				 */
				await this.storeSchedule.form.setFiltersKeys(keys);

				/**
				 * Parsed y Set de dimensiones y metricas
				 */
				await this.storeReport.setDimensionMetric({
					dimensions: await getDimensionMetricParsed(
						params.dimensions
					),
					metrics: await getDimensionMetricParsed(params.metrics),
				});

				this.attempResponse.setSuccess(true);
			} catch (error) {
				this.setLoadingData();
				console.error(`ReportScheduler::updateReport`, error);
			}
		},
		async isSelectedAll(value: Array<number>) {
			return includes(value, 0);
		},

		async handleAll(params: { key: any; value: Array<any> }) {
			this.getStoreResourceData.selected_all[params.key] = params.value;

			const items: Array<any> = (await this.isSelectedAll(params.value))
				? this.resources[params.key]
				: [];

			this.getStoreReport.filters[params.key] = items;
		},
		async handlerFocus(event: any) {
			const { key } = event;
			const emitterKey: string =
				key === "account" ? "get-account-sp" : "fetch-combo";

			const invalidAccount: Boolean =
				this.isAccountMaster &&
				key !== "account" &&
				isEmpty(this.getStoreReport.filters.account);

			if (invalidAccount) {
				this.snackbar = true;
				this.texto_snackbar = this.$t("report.messages.account");
			} else {
				this.$emit(emitterKey, key);
			}
		},
		initMutationObserver() {

            const targetNode = this.$refs.panelContainer;
            if (!targetNode) return;

            const config = {
                childList: true, 
                subtree: true, 
                attributes: true, 
            };

            const callback = (mutationsList) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === "childList") {
                        this.reloadActivePanel();
                    }
                }
            };

            this.observer = new MutationObserver(callback);
            this.observer.observe(targetNode, config);
        },
        disconnectObserver() {
            if (this.observer) {
                this.observer.disconnect();
                this.observer = null;
            }
        },
        reloadActivePanel() {
            this.activePanel = [
                ActivePanelTab.SCHEDULER_REPORT,
                ActivePanelTab.SAVE_SCHEDULE,
            ];
        },
	},
	watch: {},
});
